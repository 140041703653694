
import { Component, Vue } from 'vue-property-decorator'

import $app from '@/plugins/modules'
import { System } from '@/modules/system'

@Component
export default class PasswordSetMenu extends Vue {
  dialog = false;
  show = false;
  pwdValue = '';
  pwdConfirm = '';
  processing = false;
  validator: Record<string, boolean | string> = {};

  system = $app.module(System);

  get valid() {
    for (const v in this.validator) {
      if (this.validator[v] !== true) return false
    }
    return true
  }

  get confirmError() {
    const result =
      this.show ||
      this.pwdValue === this.pwdConfirm ||
      $app.i18n('system.PasswordNotMatch')
    Vue.set(this.validator, 'pwd_confirm', result)
    return result === true ? undefined : result
  }

  open() {
    setTimeout(() => {
      this.pwdValue = ''
      this.pwdConfirm = ''
      this.dialog = true
    }, 100)
  }

  async change() {
    this.processing = true
    try {
      await this.system.$store.userSetPass(this.pwdValue)
      this.dialog = false
    } catch (error) {
      $app.pushError(error)
    }
    this.processing = false
  }
}
